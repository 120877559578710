import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { map } from 'lodash';
import { NotificationManager } from 'react-notifications';

import User from '../../models/user';
import Manager from '../../models/manager';
import { ElectionRow, ElectionRowItem } from '../../components/Election';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';

class Content extends Component {
  data = observable({
    error: false,
    loading: true,
  });

  async componentDidMount() {
    try {
      await Manager.loadCurrentElections();
      await Manager.loadUpcomingElections();
      await Manager.loadElectionHistory();
      this.data.loading = false;
    } catch (error) {
      this.data.error = true;
      NotificationManager.error(error.message);
    }
  }

  render() {
    return (
      <div>
        <Loader show={this.data.loading} />
        <Modal id="regcodefailure" show={User.claimCodeError}>
          <div className="onboarding-content with-gradient">
            <h4 className="onboarding-title">
              Registration Attempt Failed!
            </h4>
            <div className="onboarding-text text-left">
              <p>Unfortunately your last registration attempt failed.</p>
              <p>If you've registered successfully previously, you can relax.</p>
              <p>Else logout to try again</p>
            </div>
          </div>
        </Modal>
        <ElectionRow title="Current Elections">
          {
            map(Manager.currentElections, (e) => (
              <ElectionRowItem key={e._id} data={e} />
            ))
          }
          {
            Manager.currentElections.length <= 0 && (
              <h5 className="text-center mt-5 mb-5" >No data available</h5>
            )
          }
        </ElectionRow>
        <ElectionRow title="Upcoming Elections">
          {
            map(Manager.upcomingElections, (e) => (
              <ElectionRowItem key={e._id} data={e} />
            ))
          }
          {
            Manager.upcomingElections.length <= 0 && (
              <h5 className="text-center mt-5 mb-5" >No data available</h5>
            )
          }
        </ElectionRow>
        <ElectionRow title="Election History">
          {
            map(Manager.historyElections, (e) => (
              <ElectionRowItem key={e._id} data={e} />
            ))
          }
          {
            Manager.historyElections.length <= 0 && (
              <h5 className="text-center mt-5 mb-5" >No data available</h5>
            )
          }
        </ElectionRow>
      </div >
    );
  }
}

export default observer(Content);

import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import User from '../../models/user';
import TopMenu from './TopMenu';
import Content from './Content';
import Profile from '../Profile';
import { ElectionView, ElectionVoting } from '../Election';

class Home extends Component {
  async componentDidMount() {
    try {
      await User.loadAccount();
    } catch (error) {
      NotificationManager.error('Initialization error. Please logout and login again');
    }
  }

  UNSAFE_componentWillMount() {
    document.body.className = "menu-position-top full-screen";
    document.getElementById('root').className = "all-wrapper solid-bg-all";
  }

  render() {
    if (!User.loggedIn) {
      return (
        <Redirect to='/login' />
      );
    }

    if (!User.verified) {
      return (
        <Redirect to='/verify' />
      );
    }

    return (
      <div className="layout-w">
        <TopMenu path={this.props.match.path} />
        <div className="content-w">
          <div className="content-i">
            <div className="content-box">
              <div className="element-wrapper">
                <Route path='/home' exact component={Content} />
                <Route path='/home/profile' exact component={Profile} />
                <Route path='/home/elections/:id' exact component={ElectionView} />
                <Route path='/home/elections/:id/vote' exact component={ElectionVoting} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;

import React, { Component } from 'react';
import { Subject } from 'rxjs';

class Modal extends Component {
  constructor(props) {
    super(props);
    this.eventStream = new Subject();
    this.id = props.id || Date.now();
    this.state = {
      display: false,
    }
  }

  componentDidMount() {
    const existing = document.getElementById(this.id);
    if (existing) {
      return;
    }
    this.setState({ display: true }, () => {
      this.eventSub = this.eventStream.subscribe((show) => {
        const { backdrop, keyboard } = this.props;
        window.jQuery(`#${this.id}`).modal({
          backdrop,
          show,
          keyboard,
        });
      });
      window.jQuery(`#${this.id}`).on('hidden.bs.modal', (e) => {
        this.eventStream.next(false);
        this.props.onClose && this.props.onClose();
      });
      const { show } = this.props;
      this.eventStream.next(show);
    })
  }

  componentWillUnmount() {
    if (this.eventSub) {
      this.eventStream.next(false);
      this.eventSub.unsubscribe();
    }
  }

  componentDidUpdate(prev) {
    // console.log(prev, this.props)
    const { show } = this.props;
    this.eventStream.next(show);
  }

  render() {
    const { show, className, hideClose } = this.props;
    const { display } = this.state;
    if (!display) return null;
    return (
      <div className="onboarding-modal modal fade" id={this.id} tabIndex="-1" role="dialog" aria-hidden="true">
        <div className={`modal-dialog ${className}`} role="document">
          <div className="modal-content text-center">
            {
              !hideClose && (
                <button aria-label="Close" className="close" data-dismiss="modal" type="button">
                  <span className="close-label">Close</span><span className="os-icon os-icon-close"></span>
                </button>
              )
            }
            {show && this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

Modal.defaultProps = {
  keyboard: true,
  backdrop: true,
  hideClose: false,
};

export default Modal;

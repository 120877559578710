import React, { Component } from 'react';
import { isPast } from 'date-fns';
import { Redirect } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { observable, toJS } from 'mobx';
import { map, filter, values, keys } from 'lodash';

import Manager from '../../models/manager';
import Timer from '../../components/Timer';
import { ElectionCandidate } from '../../components/Election';
import Modal from '../../components/Modal';
import { Slider, Slide } from '../../components/Onboarding';
import Loader from '../../components/Loader';

import './style.css';

class ElectionVoting extends Component {
  data = observable({
    error: false,
    index: 0,
    votes: {},
    submitted: false,
    showTutorial: true,
    loading: true,
    excludedOffices: [],
    offices: [],
    exit: false,
  });

  async componentDidMount() {
    try {
      const { match: { params: { id } } } = this.props;
      await Manager.loadElection(id);
      if (isPast(new Date(Manager.currentElection.endDate))) {
        this.data.exit = true;
        return;
      }
      await Manager.loadElectionVotes(id);
      const { currentElection, currentElectionVotes } = Manager;
      if (currentElectionVotes.length >= currentElection.offices.length) {
        this.data.submitted = true;
        NotificationManager.info('you have voted previously');
      } else {
        this.data.excludedOffices = map(currentElectionVotes, (v) => v.office);
        this.data.offices = filter(Manager.currentElection.offices, (o) => this.data.excludedOffices.indexOf(o._id) < 0);
      }
      this.data.loading = false;
    } catch (error) {
      this.data.error = true;
      NotificationManager.error(error.message);
    }
  }

  vote(office, candidate) {
    this.data.votes[office] = candidate;
  }

  async submit() {
    try {
      if (keys(this.data.votes).length < 1) {
        NotificationManager.warning('You need to vote at least one candidate in an office');
        return;
      }
      NotificationManager.info('Please hold on while the votes get submitted');
      await Manager.vote(toJS(this.data.votes));
      NotificationManager.success('Votes submitted successfully');
      this.data.submitted = true;
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  render() {
    // console.log(this.data);
    const { match: { params: { id } } } = this.props;

    if (this.data.error || this.data.submitted || this.data.exit) {
      return (
        <Redirect to={`/home/elections/${id}`} />
      )
    }

    if (
      Manager.loadingElection ||
      !Manager.currentElection._id ||
      this.data.loading
    ) {
      return <Loader show />;
    }

    return (
      <>
        <Modal
          show={this.data.showTutorial}
          onClose={() => this.data.showTutorial = false}
          id="tutor"
        >
          <Slider>
            <Slide
              media="img/bigicon6.png"
              title="Step 1"
              text="Select a candidate of your choice for the office in view"
            />
            <Slide
              media="img/bigicon6.png"
              title="Step 2"
              text="Click the next button to vote candidates in other offices"
            />
            <Slide
              media="img/bigicon6.png"
              title="Step 3"
              text="When you're satisfied with your votes, click on submit at the end. Note! your voting does not count until you submit"
            />
            <Slide
              media="img/bigicon6.png"
              title="Step 4"
              text="If you're confused, you can always come back later to finish the voting before the election ends."
            />
            <Slide
              media="img/bigicon6.png"
              title="Step 5"
              text="Ensure that you are the only one using this device. Multiple voting from same device may be invalidated."
            />
          </Slider>
        </Modal>
        <Loader show={Manager.voting} />
        <div className="row align-items-center">
          <div className="col">
            <div className="element-info-with-icon smaller justify-content-center">
              <div className="element-info-icon">
                <div className="os-icon os-icon-calendar-time"></div>
              </div>
              <div className="element-info-text">
                <h5 className="element-inner-header">
                  Time Left
                </h5>
                <div className="element-inner-desc">
                  Time left to end of election
                </div>
              </div>
            </div>
            <Timer date={Manager.currentElection.endDate} />
          </div>
        </div>
        {
          map(this.data.offices, (office, i) => (
            <div key={office._id} className={`row align-items-center mt-5 ${this.data.index === i ? '' : 'd-none'}`}>
              <div className="col animated fadeIn">
                <h4 className="text-center b-b pb-2 mb-4">{office.title}</h4>
                <div className="text-center">
                  {
                    map(filter(Manager.currentElectionCandidates, (c) => c.office === office._id), (candidate) => (
                      <ElectionCandidate
                        key={candidate._id}
                        data={candidate}
                        button={(
                          <div className="pt-btn">
                            {
                              values(this.data.votes).indexOf(candidate._id) >= 0 ? (
                                <button
                                  className="btn btn-success btn-sm"
                                  disabled
                                >
                                  <i className="os-icon os-icon-fingerprint"></i>
                                  <span>Voted Candidate</span>
                                </button>
                              ) : (
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => this.vote(office._id, candidate._id)}
                                  >
                                    <i className="os-icon os-icon-fingerprint"></i>
                                    <span>Vote This Candidate</span>
                                  </button>
                                )
                            }
                          </div>
                        )}
                      />
                    ))
                  }
                  {
                    filter(Manager.currentElectionCandidates, (c) => c.office === office._id).length <= 0 && (
                      <h5 className="text-center mt-5 mb-5" >No candidate available for this office</h5>
                    )
                  }
                </div>
              </div>
            </div>
          ))
        }
        <div className="row text-center mt-2">
          <div className="col">
            <h4 className="text-center b-b">&nbsp;</h4>
            <button
              className="btn btn-primary btn-rounded mt-2 previous"
              type="button"
              onClick={() => this.data.index > 0 && this.data.index--}
              disabled={this.data.index === 0}
            >
              <i className="os-icon os-icon-arrow-left5"></i>
              <span>Previous</span>
            </button>
            {
              this.data.index < this.data.offices.length - 1 && (
                <button
                  className="btn btn-primary btn-rounded mt-2 next"
                  type="button"
                  onClick={() => this.data.index++}
                >
                  <span>Next</span>
                  <i className="os-icon os-icon-arrow-right5"></i>
                </button>
              )
            }
            {
              this.data.index >= this.data.offices.length - 1 && (
                <button
                  className="btn btn-primary btn-rounded mt-2 submit"
                  type="button"
                  onClick={() => this.submit()}
                  disabled={Manager.voting}
                >
                  <span>Submit</span>
                  <i className="os-icon os-icon-email-forward"></i>
                </button>
              )
            }
          </div>
        </div>
      </>
    );
  }
}

export default ElectionVoting;

import React from 'react';

export const ElectionRow = ({ title, children }) => (
  <div className="element-wrapper">
    <h6 className="element-header">
      {title}
    </h6>
    {children}
  </div>
);

import React, { Component } from 'react';

export class Slider extends Component {
  componentDidMount() {
    setTimeout(() => {
      try {
        window.jQuery('.onboarding-modal .onboarding-slider-w').slick({
          dots: true,
          infinite: false,
          adaptiveHeight: true,
          slidesToShow: 1,
          slidesToScroll: 1
        });
        window.jQuery('.onboarding-modal').on('shown.bs.modal', function (e) {
          window.jQuery('.onboarding-modal .onboarding-slider-w').slick('setPosition');
        });
      } catch (error) {

      }
    }, 1000);
  }

  render() {
    return (
      <div className="onboarding-slider-w">
        {this.props.children}
      </div>
    );
  }
}

export const Slide = ({ media, title, text, ...others }) => (
  <div className="onboarding-slide">
    <div className="onboarding-media">
      <img alt="" src={media} width="200px" />
    </div>
    <div className="onboarding-content with-gradient">
      <h4 className="onboarding-title">
        {title}
      </h4>
      <div className="onboarding-text">
        {text}
      </div>
      {others.children}
    </div>
  </div>
);

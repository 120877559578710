import React, { Component } from 'react';
import { isPast } from 'date-fns';
import { NavLink, Redirect } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { observable } from 'mobx';
import { map, filter, find, orderBy } from 'lodash';

import Manager from '../../models/manager';
import Timer from '../../components/Timer';
import { ElectionCandidate, ElectionCandidateWinner } from '../../components/Election';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';

class ElectionView extends Component {
  data = observable({
    error: false,
    loading: true,
  });

  async componentDidMount() {
    try {
      const { match: { params: { id } } } = this.props;
      await Manager.loadElection(id);
      this.loading = false;
      // if (!isPast(new Date(Manager.currentElection.endDate)) && isPast(new Date(Manager.currentElection.startDate))) {
      //   this.refresh();
      //   NotificationManager.info("You don't need to refresh manually. Page will auto refresh every minute");
      // }
    } catch (error) {
      this.data.error = true;
      NotificationManager.error(error.message);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.refreshTimeout);
    this.unmounted = true;
  }

  refresh() {
    this.refreshTimeout = setTimeout(() => {
      NotificationManager.info('refreshing data...');
      Manager.loadCurrentElectionResults();
      if (!this.unmounted) this.refresh();
    }, 60000);
  }

  render() {
    const { match: { params: { id } } } = this.props;

    if (this.data.error) {
      return (
        <Redirect to="/home" />
      )
    }

    if (Manager.loadingElection || !Manager.currentElection._id) {
      return <Loader show />;
    }

    return (
      <>
        <Modal id="regcodefailure" show={!Manager.canParticipateInCurrentElection && Manager.isCurrentElectionOngoing()}>
          <div className="onboarding-content with-gradient">
            <h4 className="onboarding-title">
              Unable to vote?
            </h4>
            <div className="onboarding-text text-left">
              <p>1. Request for your election registration details.</p>
              <p>2. Logout.</p>
              <p>3. Enter your registration details to register for the election.</p>
            </div>
          </div>
        </Modal>
        <div className="row align-items-center">
          <div className="col">
            <div className="element-info-with-icon smaller justify-content-center">
              <div className="element-info-icon">
                {isPast(new Date(Manager.currentElection.endDate)) ? (
                  <div className="os-icon os-icon-hierarchy-structure-2"></div>
                ) : (
                  <div className="os-icon os-icon-calendar-time"></div>
                )}
              </div>
              <div className="element-info-text">
                <h5 className="element-inner-header">
                  {isPast(new Date(Manager.currentElection.endDate)) ? 'Result Summary' : isPast(new Date(Manager.currentElection.startDate)) ? 'Time Left' : 'Countdown'}
                </h5>
                <div className="element-inner-desc">
                  {isPast(new Date(Manager.currentElection.endDate)) ? 'Detailed summary of results' : isPast(new Date(Manager.currentElection.startDate)) ? 'Time left to end of election' : 'Time left before election starts'}
                </div>
              </div>
            </div>
            {isPast(new Date(Manager.currentElection.endDate)) ? (
              <div className="row">
                {
                  map(Manager.currentElection.offices, (office) => {
                    const results = orderBy(filter(Manager.currentElectionResults, (r) => r.office === office._id), 'votes', 'desc');
                    const winner1 = results[0];
                    const winner2 = results[1];
                    if (winner1 && winner2 && winner1.votes === winner2.votes) {
                      const candidate1 = find(Manager.currentElectionCandidates, { _id: winner1.candidate });
                      const candidate2 = find(Manager.currentElectionCandidates, { _id: winner2.candidate });
                      return (
                        <div key={candidate1._id}>
                          <div className="col text-md-center">
                            <ElectionCandidateWinner data={candidate1} office={office.title} votes={winner1.votes} />
                            <ElectionCandidateWinner data={candidate2} office={office.title} votes={winner2.votes} />
                          </div>
                        </div>
                      )
                    } else if (winner1) {
                      const candidate1 = find(Manager.currentElectionCandidates, { _id: winner1.candidate });
                      return (
                        <div className="col text-md-center" key={candidate1._id}>
                          <ElectionCandidateWinner data={candidate1} office={office.title} votes={winner1.votes} />
                        </div>
                      )
                    }
                  })
                }
              </div>
            ) : isPast(new Date(Manager.currentElection.startDate)) ? <Timer date={Manager.currentElection.endDate} /> : <Timer date={Manager.currentElection.startDate} />}
          </div>
        </div>
        {
          Manager.canParticipateInCurrentElection && (
            <div className="row text-center mt-5">
              <div className="col">
                <NavLink to={`/home/elections/${id}/vote`} className="btn btn-primary btn-rounded animated bounce">
                  <i className="os-icon os-icon-fingerprint"></i>
                  <span>Cast Vote</span>
                </NavLink>
              </div>
            </div>
          )
        }
        {
          map(Manager.currentElection.offices, (office) => (
            <div key={office._id} className="row align-items-center mt-5">
              <div className="col">
                <h4 className="text-center b-b pb-2 mb-4">{office.title}</h4>
                <div className="row">
                  {
                    map(filter(Manager.currentElectionCandidates, (c) => c.office === office._id), (candidate) => (
                      <div className="col text-md-center" key={candidate._id}>
                        <ElectionCandidate data={candidate} />
                      </div>
                    ))
                  }
                </div>
                {
                  filter(Manager.currentElectionCandidates, (c) => c.office === office._id).length <= 0 && (
                    <h5 className="text-center mt-5 mb-5" >No candidate available for this office</h5>
                  )
                }
              </div>
            </div>
          ))
        }
      </>
    );
  }
}

export default ElectionView;

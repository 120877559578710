import { Component } from 'react';
import propTypes from 'prop-types';
import { isEqual } from 'lodash';

import { request } from '../../services/request';

class WithURILoad extends Component {
  state = {
    error: undefined,
    loading: true,
    data: undefined
  };

  constructor(props) {
    super(props);
    this.mounted = true;
  }

  async componentDidMount() {
    await this.load();
  }

  componentDidUpdate(oldProps) {
    const { requestConfig } = this.props;
    if (!isEqual(requestConfig, oldProps.requestConfig)) {
      this.load();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async load() {
    try {
      const { URI, cache, cacheTime, requestConfig } = this.props;
      const key = `cache:${cache}`;
      if (cache) {
        const data = await window.localStorage.getItem(key);
        if (data) {
          const cacheData = JSON.parse(data);
          if (this.mounted && Date.now() - cacheData.time < cacheTime) {
            // console.log('cached', cacheData);
            this.setState({
              data: cacheData.data, loading: false, error: undefined
            });
            return;
          }
        }
      }
      if (!URI) {
        if (this.mounted) this.setState({ loading: false, error: 'no URI specified for request' });
        return;
      }
      if (this.mounted) {
        const data = await request('GET', URI, requestConfig);
        if (this.mounted) this.setState({ data, loading: false, error: undefined });
        if (cache) {
          window.localStorage.setItem(key, JSON.stringify({ data, time: Date.now() }));
        }
      }
    } catch (error) {
      // console.log('WithURILoad', error.message);
      if (this.mounted) this.setState({ loading: false, error: error.message });
    }
  }

  render() {
    const { loading, data, error } = this.state;
    const { children } = this.props;
    return children({
      URILoading: loading,
      URIData: data || {},
      URIError: error,
      load: this.load.bind(this)
    });
  }
}

WithURILoad.propTypes = {
  URI: propTypes.string,
  requestConfig: propTypes.shape({}),
};

WithURILoad.defaultProps = {
  URI: undefined,
  requestConfig: {},
};

export default WithURILoad;

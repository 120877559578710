/* eslint-disable no-console */
import axios from 'axios';
import {
  template,
  templateSettings,
  get,
  keys,
} from 'lodash';

templateSettings.interpolate = /{([\s\S]+?)}/g;

/**
 * sends a request using the post method
 * @param {object} data
 * @returns {Promise}
 */
export const postFormData = async (url, data, headers) => {
  try {
    const form = new FormData();
    keys(data).forEach((key) => {
      form.append(key, data[key]);
    });
    const requestConfig = {
      url,
      data: form,
      method: 'post',
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      },
    };
    // console.log(requestConfig);
    const result = await axios(requestConfig);
    return result.data;
  } catch (error) {
    // console.log(error.response.data);
    throw new Error(get(error, 'response.data.message', 'server error'));
  }
};

/**
 *
 * @param {String} method GET|PUT|POST|DELETE
 * @param {String} path
 * @param {{ url, body, query, headers: Object }} data
 */
export const request = async (method, path, data) => {
  try {
    const compiled = template(path);
    const url = data.url ? `${data.url}${compiled({ ...data.params })}` : path;
    const requestConfig = {
      url,
      method,
      headers: {
        ...data.headers,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    if (data.body) requestConfig.data = data.body;
    if (data.query) requestConfig.params = data.query;
    // console.log(requestConfig);
    const result = await axios(requestConfig);
    // console.log(result.data);
    return result.data;
  } catch (error) {
    // console.log(error);
    throw new Error(get(error, 'response.data.message', 'server error'));
  }
};

/**
 *
 * @param {String} method GET|PUT|POST|DELETE
 * @param {String} path
 * @param {{ url, body, query, headers: Object }} data
 */
export const apiRequest = async (method, path, data) => {
  const headers = { ...data.headers };
  const result = await request(method, path, { ...data, headers });
  return result;
};

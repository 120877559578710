import React, { Component } from 'react';
import { observable, toJS } from 'mobx';
import Dropzone from 'react-dropzone';
import { get, pick, keys } from 'lodash';
import { NotificationManager } from 'react-notifications';

import User from '../../models/user';

import './style.css';

class Profile extends Component {
  data = observable({});

  componentDidMount() {
    this.reader = new FileReader();

    this.reader.addEventListener("load", () => {
      this.data.avatarPreview = this.reader.result;
    }, false);
  }

  handleImage(file) {
    this.reader.readAsDataURL(file);
    this.data.avatarFile = file;
  }

  async update() {
    const update = toJS(this.data);
    if (update.newPassword) {
      try {
        const { oldPassword, newPassword, confirmPassword } = update;
        if (confirmPassword !== newPassword) throw new Error('password mismatch');
        if (newPassword.length < 6) throw new Error('password must contain 6 or more characters');
        await User.updatePassword({ oldPassword, newPassword });
        NotificationManager.success('Profile update successful');
      } catch (error) {
        NotificationManager.error(error.message);
        return;
      }
    }
    const accountUpdate = pick(update, ['firstName', 'lastName', 'contactEmail', 'phone', 'avatar']);
    if (update.avatarFile) {
      accountUpdate.avatar = await User.uploadImage(update.avatarFile);
    }
    if (keys(accountUpdate).length > 0) {
      try {
        await User.update(accountUpdate);
        NotificationManager.success('Profile update successful');
      } catch (error) {
        NotificationManager.error(error.message);
      }
    }
  }

  render() {
    if (User.loadingAccount) return null;
    return (
      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-8">
          <div className="element-wrapper">
            <div className="element-box">
              <form id="formValidate" onSubmit={(e) => e.preventDefault()}>
                <div className="element-info">
                  <div className="element-info-with-icon">
                    <div className="element-info-icon">
                      <div className="os-icon os-icon-user-male-circle2"></div>
                    </div>
                    <div className="element-info-text">
                      <h5 className="element-inner-header">
                        Profile Settings
                      </h5>
                      <div className="element-inner-desc">
                        Ensure you fill in the correct data
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-4 text-center">
                    <div className="avatar-wrapper">
                      <img alt="" src={this.data.avatarPreview || get(User.account, 'avatar.url') || '/img/avatar1.jpg'} />
                      <Dropzone
                        accept="image/jpeg, image/png"
                        onDrop={(files) => {
                          this.handleImage(files[0]);
                        }}
                      >
                        {
                          ({ getRootProps, getInputProps }) => (
                            <div className="avatar-backdrop" {...getRootProps()}>
                              <input {...getInputProps()} />
                            </div>
                          )
                        }
                      </Dropzone>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-group">
                      <label htmlFor="">First Name</label>
                      <input
                        className="form-control"
                        placeholder="Enter First Name"
                        defaultValue={User.account.firstName}
                        onChange={(e) => this.data.firstName = e.target.value}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Last Name</label>
                      <input
                        className="form-control"
                        placeholder="Enter Last Name"
                        defaultValue={User.account.lastName}
                        onChange={(e) => this.data.lastName = e.target.value}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Contact Email</label>
                      <input
                        className="form-control"
                        placeholder="Enter Contact Email"
                        type="email"
                        defaultValue={User.account.contactEmail}
                        onChange={(e) => this.data.contactEmail = e.target.value}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Phone</label>
                      <input
                        className="form-control"
                        placeholder="Enter Phone"
                        type="phone"
                        defaultValue={User.account.phone}
                        onChange={(e) => this.data.phone = e.target.value}
                      />
                    </div>
                    <fieldset className="form-group">
                      <legend><span>Password Update</span></legend>
                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="">Current Password</label>
                            <input
                              className="form-control"
                              placeholder="Current Password"
                              type="password"
                              onChange={(e) => this.data.oldPassword = e.target.value}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor=""> Password</label>
                            <input
                              className="form-control"
                              data-minlength="6"
                              placeholder="Password"
                              type="password"
                              onChange={(e) => this.data.newPassword = e.target.value}
                            />
                            <div className="help-block form-text text-muted form-control-feedback">
                              Minimum of 6 characters
                        </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="">Confirm Password</label>
                            <input
                              className="form-control"
                              placeholder="Confirm Password"
                              type="password"
                              onChange={(e) => this.data.confirmPassword = e.target.value}
                            />
                            <div className="help-block form-text with-errors form-control-feedback"></div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <div className="form-buttons-w">
                      <button
                        className="btn btn-primary"
                        onClick={this.update.bind(this)}
                        // disabled={User.updating || User.updatingPassword || User.uploading}
                        disabled
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Profile;

import React, { Component, createRef } from 'react';
import Webcam from 'react-webcam';
import * as faceapi from 'face-api.js';
import resemble from 'resemblejs';

import { NotificationManager } from 'react-notifications';

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        // Pick a random index from 0 to i
        const j = Math.floor(Math.random() * (i + 1));
        // Swap elements array[i] and array[j]
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

function isMouthOpen(landmarks) {
    const upperLip = landmarks.getMouth()[13];
    const lowerLip = landmarks.getMouth()[14];
    // console.log(landmarks.getMouth())
    const lipDistance = lowerLip.y - upperLip.y;
    return lipDistance > 20; // You might need to tweak this threshold
}

function getFaceSide(landmarks) {
    // Get landmarks for left eye, right eye, and nose
    const leftEye = landmarks.getLeftEye();
    const rightEye = landmarks.getRightEye();
    const nose = landmarks.getNose();

    // Calculate the x-coordinates
    const leftEyeX = leftEye[0].x;
    const rightEyeX = rightEye[0].x;
    const noseX = nose[0].x;

    // console.log({ leftEyeX, rightEyeX, noseX })

    // Determine face orientation
    const noseToLeft = (noseX - leftEyeX);
    const noseToRight = (noseX - rightEyeX);
    const leftToRight = (leftEyeX - rightEyeX);
    // console.log({ noseToLeft, noseToRight, leftToRight });
    if (leftEyeX < noseX && rightEyeX > noseX) {
        return 'Straight';
    } else if (leftEyeX > noseX) {
        return 'Right';
    } else if (rightEyeX < noseX) {
        return 'Left';
    }
    return '';
}

class Check {
    message = '';
    expression = '';
    image = '';
    verified = false;

    /**
     * 
     * @param {Check} data 
     */
    constructor(data) {
        Object.assign(this, data);
    }
}

const checks = [
    new Check({
        message: 'Please keep a neutral face and no movements',
        expression: 'neutral',
    }),
    new Check({
        message: 'Please smile and no movements',
        expression: 'happy',
    }),
    new Check({
        message: 'Please raise your brows and widen your eyes',
        expression: 'surprised',
    }),
    new Check({
        message: 'Please make a neutral face and no movements',
        expression: 'neutral',
    }),
];

/**
 * 
 * @returns {Array<number>}
 */
const getStages = () => {
    const indexes = [];
    for (let i = 1; i < checks.length - 1; i++) {
        indexes.push(Number(i));
    }
    const shuffled = shuffleArray(indexes);
    return [0, ...shuffled, checks.length - 1];
}

class LivenessCheck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            /**
             * @type {Array<Check>}
             */
            checkList: [],
            faceDetected: 'No Face Detected',
            stage: 0,
            ok: false,
            invalidationCount: 0,
            message: '',
            modelsLoaded: false,
        };
        this.webcamRef = createRef();
        this.unmounted = false;
        this.startCapture = this.debounce(this.capture, 1000);
        this.debouncedCapture = this.debounce(this.captureHandler, 1000);
    }

    async componentDidMount() {
        this.setup();
        NotificationManager.info('loading, please hold');
        await Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
            faceapi.nets.ssdMobilenetv1.loadFromUri('/models'),
            faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
            faceapi.nets.faceExpressionNet.loadFromUri('/models'),
        ]);
        this.setState({ modelsLoaded: true });
        NotificationManager.info('you can now start the process');
    }

    componentWillUnmount() {
        this.unmounted = true;
        // console.log('unmounted');
    }

    setup(callback) {
        const stages = getStages();
        const checkList = [];
        for (const item of stages) {
            checkList.push(new Check(checks[item]));
        }
        this.setState({
            checkList: checkList, ok: false, stage: 0, invalidationCount: 0
        }, callback);
    }

    /**
     * 
     * @param {number} index 
     * @param {Check} data 
     */
    updateCheck(index, data, callback) {
        const checkList = this.state.checkList;
        const current = checkList[index];
        const updated = Object.assign(current, data);
        checkList[index] = updated;
        this.setState({ checkList }, callback);
    }

    debounce(func, delay) {
        let timeout;
        return (...args) => {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    }

    capture() {
        this.debouncedCapture();
    }

    async captureHandler() {
        if (this.unmounted) return;
        if (this.state.ok || this.state.stage >= checks.length) {
            this.validateFaces();
            return;
        }
        if (!this.state.modelsLoaded) {
            setTimeout(this.capture.bind(this), 2000);
        }
        // console.log('capturing');
        document.getElementById('webcam').style.overflow = 'hidden';
        const imageSrc = this.webcamRef.current.getScreenshot();
        this.updateCheck(this.state.stage, { image: imageSrc }, async () => {
            if (this.unmounted) return;
            // console.log('detecting face', imageSrc);
            try {
                await this.detectFace(imageSrc);
            } catch (error) {
                setTimeout(this.capture.bind(this), 2000);
            }
        });
    };

    error(message, show) {
        if (show) NotificationManager.error(message);
    }

    info(message, show) {
        if (show) NotificationManager.success(message);
    }

    proceed = async () => {
        const neutrals = this.state.checkList.filter(x => x.expression === 'neutral');
        const [check1, check2] = neutrals;
        this.props.onMatch && this.props.onMatch(check2.image);
    }

    validateFaces = () => {
        // compare first image with smile image
        const neutrals = this.state.checkList.filter(x => x.expression === 'neutral');
        const [check1, check2] = neutrals;
        resemble(check1.image)
            .compareTo(check2.image)
            .ignoreColors()
            .onComplete((data) => {
                // console.log(data);
                if (data.misMatchPercentage <= 50) {
                    this.info('process completed. please hold', true);
                    const img = document.createElement('img');
                    img.src = this.state.checkList[this.state.checkList.length - 1].image;
                    img.className = 'fill';
                    const div = document.getElementById('webcam');
                    div.removeChild(div.firstChild);
                    div.appendChild(img);
                    this.proceed();
                } else {
                    this.error('some faces did not match properly');
                    this.setup(() => {
                        this.info('retrying face match');
                        setTimeout(this.capture.bind(this), 2000);
                    });
                }
            });
    }

    detectFace = async (imageSrc) => {
        const img = new Image();
        img.src = imageSrc;
        img.onload = async () => {
            const currentCheck = this.state.checkList[this.state.stage];
            // await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
            // console.log('calling detection api');            

            const detections = await faceapi.detectSingleFace(img, new faceapi.TinyFaceDetectorOptions())
                .withFaceExpressions();
            if (detections) {
                const { detection, expressions } = detections;
                // console.log(expressions);
                // console.log({ expression: currentCheck.expression, stage: this.state.stage });
                const expScore = expressions[currentCheck.expression];
                // console.log(expressions);
                // console.log(`expression ${currentCheck.expression}: ${expScore}`);
                // console.log('detection:', detection ? detection.score : '');
                const ok = detection.score > 0.7;
                if (!ok) {
                    setTimeout(this.capture.bind(this), 2000);
                } else {
                    this.setState({
                        faceDetected: ok ? 'Face Detected' : 'No Face Detected',
                        ok: this.state.stage >= checks.length,
                    }, () => {
                        if (expScore < 0.6) {
                            // this.info(`please keep a ${currentCheck.expression} face`, true);
                            this.info(currentCheck.message + ` ${this.state.invalidationCount}`);
                            if (this.state.stage > 0 && this.state.invalidationCount > 2) {
                                this.setup(() => {
                                    this.info('retrying face match');
                                    setTimeout(this.capture.bind(this), 2000);
                                });
                            } else {
                                this.setState({
                                    faceDetected: 'Invalid Facial Expression',
                                    invalidationCount: this.state.invalidationCount + 1
                                }, () => {
                                    setTimeout(this.capture.bind(this), 1000);
                                });
                            }
                            return;
                        }
                        if (this.state.ok) {
                            this.validateFaces();
                        } else {
                            this.updateCheck(this.state.stage, { image: imageSrc, verified: true });
                            // advance to next stage
                            this.setState({ stage: this.state.stage + 1, invalidationCount: 0 }, () => {
                                if (this.state.stage >= checks.length) {
                                    setTimeout(this.capture.bind(this), 1000);
                                } else {
                                    setTimeout(this.capture.bind(this), 2000);
                                }
                            });
                        }
                    });
                }
            } else {
                if (this.state.invalidationCount >= 2) {
                    if (this.state.stage > 0) {
                        this.error('movement detected');
                        this.setup(() => {
                            this.info('retrying face match');
                            setTimeout(this.capture.bind(this), 2000);
                        });
                    } else {
                        setTimeout(this.capture.bind(this), 2000);
                    }
                } else {
                    this.setState({
                        faceDetected: 'No Face Detected',
                        invalidationCount: this.state.invalidationCount + 1
                    }, () => {
                        setTimeout(this.capture.bind(this), 1000);
                    });
                }
            }
        };
    };

    render() {
        return (
            <div className="text-center">
                {/* <div className="text-center mt-2 mb-2">
                    <p>{this.state.message}</p>
                </div> */}
                {
                    !this.state.ok && this.state.stage < checks.length ? (<div className="text-center mt-2 mb-2">
                        <p>Position your face in the circle below</p>
                    </div>) : (<div></div>)
                }
                <div className="text-center mt-2 mb-2">
                    <p>{this.state.checkList.length > 0 && this.state.stage < checks.length ? this.state.checkList[this.state.stage].message : ''}</p>
                </div>
                <div id="webcam" className="rounded-circle fill-container" style={{
                    width: '40vh',
                    height: '40vh',
                    background: 'black',
                }}>
                    <Webcam
                        audio={false}
                        ref={this.webcamRef}
                        screenshotFormat="image/jpeg"
                        onCanPlay={this.startCapture}
                        className='fill'
                    />
                </div>
                <div className="text-center mt-2 mb-2">
                    <button disabled={!this.state.ok && this.state.stage < checks.length} className="btn btn-primary" onClick={this.proceed}>{this.state.faceDetected}</button>
                </div>
            </div>
        );
    }
}

export default LivenessCheck;
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import { observer } from 'mobx-react';

import 'react-notifications/lib/notifications.css';

import Variables from './variables';
import User from './models/user';
import Login from './screens/Login';
import Home from './screens/Home';
import Loader from './components/Loader';
import Verification from './screens/Verification';


class App extends Component {
  async componentDidMount() {
    // setup app
    const title = document.createElement('title');
    const favicon = document.createElement('link');
    await Variables.load();
    favicon.rel = "shortcut icon";
    favicon.href = Variables.FAVICON;
    title.innerText = Variables.TITLE;
    document.head.appendChild(favicon);
    document.head.appendChild(title);

    // load user
    await User.initialize();
  }

  render() {
    if (User.initializing) return <Loader show />
    return (
      <Router>
        <>
          <Loader show={User.loadingAccount} />
          <Route path='/' exact render={(match) => {
            if (!User.loggedIn) {
              return (
                <Redirect to='/home' />
              );
            } else {
              return (
                <Redirect to={`/login${window.location.search}`} />
              );
              // return (
              //   <Redirect to={`/verify`} />
              // );
            }
          }} />
          <Route path="/login" exact component={Login} />
          <Route path="/verify" exact component={Verification} />
          <Route path="/home" component={Home} />
          {/* <Route path="/register" component={Registration} /> */}
          <NotificationContainer />
        </>
      </Router>
    );
  }
}

export default observer(App);

import React, { Component } from 'react';
import { observable } from 'mobx';
import { forEach } from 'lodash';

import './style.css';

/**
 * 
 * @param {Date} date1 
 * @param {Date} date2 
 */
const getData = (date1, date2) => {
  const result = {
    isPast: false,
    diff: 0,
    days: 0, hours: 0, minutes: 0, seconds: 0,
  };
  let diff = date2.getTime() - date1.getTime();
  if (diff < 0) result.isPast = true;
  diff = Math.abs(diff);
  let remainder = 0;
  // get days
  result.days = Math.floor(diff / (24 * 60 * 60 * 1000));
  remainder = diff % (24 * 60 * 60 * 1000);
  // get hours
  result.hours = Math.floor(remainder / (60 * 60 * 1000));
  remainder = remainder % (60 * 60 * 1000);
  // get minutes
  result.minutes = Math.floor(remainder / (60 * 1000));
  remainder = remainder % (60 * 1000);
  // get seconds
  result.seconds = Math.floor(remainder / (1000));
  result.diff = diff;
  return result;
}

class Timer extends Component {
  data = observable({
    now: Date.now()
  });

  componentDidMount() {
    const { date } = this.props;
    this.interval = setInterval(() => {
      const data = getData(new Date(), new Date(date));
      forEach(data, (value, key) => {
        this.data[key] = value;
      });
      if (data.days + data.hours + data.minutes + data.seconds === 0 || data.isPast) {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className="text-center timer-component">
        {
          this.data.days > 0 && (
            <div className="profile-tile">
              <a className="profile-tile-box" href="# ">
                <div className="pt-avatar-w">
                  <h4 className={`${this.data.diff < 60000 ? 'text-danger animated flash infinite' : ''}`}>{this.data.days}</h4>
                </div>
                <div className="pt-user-name">
                  Days
              </div>
              </a>
            </div>
          )
        }
        <div className="profile-tile">
          <a className="profile-tile-box" href="# ">
            <div className="pt-avatar-w">
              <h4 className={`${this.data.diff < 60000 ? 'text-danger animated flash' : ''}`}>{this.data.hours || 0}</h4>
            </div>
            <div className="pt-user-name">
              Hours
            </div>
          </a>
        </div>
        <div className="profile-tile">
          <a className="profile-tile-box" href="# ">
            <div className="pt-avatar-w">
              <h4 className={`${this.data.diff < 60000 ? 'text-danger animated flash' : ''}`}>{this.data.minutes || 0}</h4>
            </div>
            <div className="pt-user-name">
              Minutes
            </div>
          </a>
        </div>
        <div className="profile-tile">
          <a className="profile-tile-box" href="# ">
            <div className="pt-avatar-w">
              <h4 className={`${this.data.diff < 60000 ? 'text-danger animated flash' : ''}`}>{this.data.seconds || 0}</h4>
            </div>
            <div className="pt-user-name">
              Seconds
            </div>
          </a>
        </div>
      </div>
    );
  }
}

export default Timer;

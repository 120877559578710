import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import User from '../../models/user';
import Variables from '../../variables';
import LivenessCheck from './LivenessCheck';

class Verification extends Component {
  UNSAFE_componentWillMount() {
    document.body.className = "auth-wrapper";
    document.getElementById('root').className = "all-wrapper menu-side with-pattern";
  }

  onMatch = async (image) => {
    if (User.verifying) return;
    try {
      NotificationManager.info('verifying');
      // Convert base64 string to a Blob
      const byteString = atob(image.split(',')[1]);
      const mimeString = image.split(',')[0].split(':')[1].split(';')[0];

      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([arrayBuffer], { type: mimeString });
      const uploadRes = await User.uploadImage(blob);
      await User.verify(uploadRes.url);
      NotificationManager.success('verified');
    } catch (error) {
      NotificationManager.error(error.message || error);
    }
  }

  render() {
    if (User.verified) {
      return (
        <Redirect to='/home' />
      );
    }
    if (!User.loggedIn) {
      return (
        <Redirect to='/login' />
      );
    }

    return (
      <div className="auth-box-w animated zoomIn">
        <div className="logo-w">
          <a href={Variables.WEBSITE_URL}><img alt="" width="100px" src={Variables.LOGO} /></a>
        </div>
        <h4 className="auth-header">
          User Verification
        </h4>
        <p className='container text-center text-info'>Please ensure you're in a well lit environment, and avoid movements as much as possible.
          <br />You can always refresh the page to restart the process.</p>
        <div>
          <LivenessCheck onMatch={this.onMatch} />
        </div>
        <div className='text-center'>
          <button className="btn btn-warning" onClick={() => User.logout()}>Logout</button>
        </div>
      </div>
    );
  }
}

export default Verification;

import { observable } from 'mobx';
import { map } from 'lodash';
import { isPast, isFuture } from 'date-fns';

import { apiRequest } from '../services/request';
import variables from '../variables';

import User from './user';

const Manager = {
  loadingElection: false,
  loadingCurrentElections: false,
  loadingUpcomingElections: false,
  loadingHistoryElections: false,
  loadingCurrentElectionResults: false,
  voting: false,
  currentElection: {},
  currentElectionCandidates: [],
  currentElectionVotes: [],
  currentElectionResults: [],
  canParticipateInCurrentElection: false,
  currentElections: [],
  upcomingElections: [],
  historyElections: [],

  checkAuthError: function (error) {
    if (String(error).match(/operation\s{1,}not\s{1,}allowed/i)) {
      console.log('auth error found in manager');
      User.logout();
    }
  },

  loadElection: async function (id) {
    try {
      this.loadingElection = true;
      this.currentElection = {};
      this.currentElectionCandidates = [];
      this.canParticipateInCurrentElection = false;
      this.currentElectionResults = [];
      const promises = [
        apiRequest('GET', `/elections/${id}`, {
          url: variables.API_URL,
          query: { status: 'DEFAULT', _populate: 'offices' },
          headers: {
            authorization: `Bearer ${User.data._token}`
          }
        }),
        apiRequest('GET', '/candidates', {
          url: variables.API_URL,
          query: { election: id, status: 'ACTIVE' },
          headers: {
            authorization: `Bearer ${User.data._token}`
          }
        }),
        apiRequest('GET', `/elections/${id}/canParticipate`, {
          url: variables.API_URL,
          headers: {
            authorization: `Bearer ${User.data._token}`
          }
        }),
        apiRequest('GET', '/results', {
          url: variables.API_URL,
          query: { election: id },
          headers: {
            authorization: `Bearer ${User.data._token}`
          }
        }),
      ];
      const [election, candidates, canParticipate, results] = await Promise.all(promises);
      this.currentElection = election;
      this.currentElectionCandidates = candidates;
      this.canParticipateInCurrentElection = canParticipate.success;
      this.currentElectionResults = results || [];
      this.loadingElection = false;
    } catch (error) {
      this.loadingElection = false;
      this.checkAuthError(error);
      throw error;
    }
  },

  isCurrentElectionOngoing: function () {
    if (!this.currentElection) return false;
    const { startDate, endDate } = this.currentElection;
    return isPast(new Date(startDate)) && isFuture(new Date(endDate))
  },

  loadCurrentElectionResults: async function () {
    try {
      this.loadingCurrentElectionResults = true;
      const data = await apiRequest('GET', '/results', {
        url: variables.API_URL,
        query: { election: this.currentElection._id },
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.currentElections = data;
      this.loadingCurrentElectionResults = false;
    } catch (error) {
      this.loadingCurrentElectionResults = false;
      throw error;
    }
  },

  loadCurrentElections: async function () {
    try {
      this.loadingCurrentElections = true;
      const data = await apiRequest('GET', '/elections', {
        url: variables.API_URL,
        query: {
          status: 'DEFAULT',
          startDate: { $lte: Date.now() },
          endDate: { $gte: Date.now() }
        },
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.currentElections = data;
      this.loadingCurrentElections = false;
    } catch (error) {
      this.loadingCurrentElections = false;
      this.checkAuthError(error);
      throw error;
    }
  },

  loadUpcomingElections: async function () {
    try {
      this.loadingUpcomingElections = true;
      const data = await apiRequest('GET', '/elections', {
        url: variables.API_URL,
        query: {
          status: 'DEFAULT',
          startDate: { $gt: Date.now() },
        },
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.upcomingElections = data;
      this.loadingUpcomingElections = false;
    } catch (error) {
      this.loadingUpcomingElections = false;
      this.checkAuthError(error);
      throw error;
    }
  },

  loadElectionHistory: async function () {
    try {
      this.loadingElection = true;
      const data = await apiRequest('GET', '/elections', {
        url: variables.API_URL,
        query: {
          status: 'DEFAULT',
          endDate: { $lt: Date.now() },
          _sort: 'createdAt:-1',
        },
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.historyElections = data;
      this.loadingElection = false;
    } catch (error) {
      this.loadingElection = false;
      this.checkAuthError(error);
      throw error;
    }
  },

  /**
   * 
   * @param {Array<{ String: String }>} data 
   */
  vote: async function (data) {
    try {
      this.voting = true;
      const promises = map(data, (candidate, office) => {
        const vote = {
          election: this.currentElection._id,
          candidate,
          office,
        }
        return apiRequest('POST', '/votes', {
          url: variables.API_URL,
          body: vote,
          headers: {
            authorization: `Bearer ${User.data._token}`
          }
        });
      });
      await Promise.all(promises);
      this.voting = false;
    } catch (error) {
      this.voting = false
      throw error;
    }
  },

  loadElectionVotes: async function (electionId) {
    try {
      this.loadingElectionVotes = true;
      const [account] = await apiRequest('GET', '/accounts', {
        url: variables.API_URL,
        query: {
          user: User.data._id
        },
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      const data = await apiRequest('GET', '/votes', {
        url: variables.API_URL,
        query: {
          election: electionId, account: account._id,
        },
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.currentElectionVotes = data;
      this.loadingElectionVotes = false;
    } catch (error) {
      this.loadingElectionVotes = false;
      throw error;
    }
  },
}

export default observable(Manager);

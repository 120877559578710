import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { observable } from 'mobx';
import { NotificationManager } from 'react-notifications';

import User from '../../models/user';
import Variables from '../../variables';

class Login extends Component {
  data = observable({
    show: 'login',
    isElectionRegistration: false,
  });

  UNSAFE_componentWillMount() {
    document.body.className = "auth-wrapper";
    document.getElementById('root').className = "all-wrapper menu-side with-pattern";
  }

  handleChange = (name, ev) => {
    this.data[name] = ev.target.value;
  };

  async login(e) {
    try {
      e.preventDefault();
      // const { userId, password } = this.data;
      // if (String(userId).indexOf('@') < 0 && String(userId).length > 11) {
      //   User.registrationCodeData = { userId, password };
      //   this.data.isElectionRegistration = true;
      // } else {
      // }
      await User.login(this.data.userId, this.data.password);
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  async resetPassword(e) {
    try {
      e.preventDefault();
      const { userId, registrationCode, registrationCodePassword, newPassword, confirmNewPassword } = this.data;
      if (newPassword !== confirmNewPassword) {
        NotificationManager.error('your passwords dont match');
        return;
      }
      await User.resetPassword({ userId, code: registrationCode, codePassword: registrationCodePassword, password: newPassword });
      NotificationManager.success('Reset success. Proceed to Login');
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  render() {
    if (User.loggedIn) {
      return (
        <Redirect to='/verify' />
      );
    }
    if (this.data.isElectionRegistration) {
      return (
        <Redirect to='/login' />
      );
    }
    return (
      <>
        <div className="auth-box-w animated zoomIn">
          <div className="logo-w">
            <a href={Variables.WEBSITE_URL}><img alt="" width="100px" src={Variables.LOGO} /></a>
          </div>
          <h4 className="auth-header">
            {this.data.show === 'recover' ? 'Reset Password' : 'Login'}
          </h4>
          {
            this.data.show === 'login' &&
            <form action="" onSubmit={this.login.bind(this)}>
              <div className="form-group">
                <label htmlFor="">User ID</label>
                <input
                  className="form-control"
                  placeholder="User ID"
                  defaultValue={this.data.userId}
                  onChange={this.handleChange.bind(this, 'userId')}
                />
                <div className="pre-icon os-icon os-icon-user-male-circle"></div>
              </div>
              <div className="form-group">
                <label htmlFor="">Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Your Password"
                  defaultValue={this.data.password}
                  onChange={this.handleChange.bind(this, 'password')}
                />
                <div className="pre-icon os-icon os-icon-fingerprint"></div>
              </div>
              <div className="buttons-w text-right">
                <button
                  className="btn btn-primary"
                  disabled={User.loggingIn}
                  onClick={this.login.bind(this)}
                >
                  Log me in
                </button>
              </div>
              {/* <br />
              <div className="row">
                <div className="col-md-12">
                  <a href="# " className="link" onClick={() => this.data.show = 'recover'}>
                    I forgot my password
                  </a>
                </div>
              </div> */}
            </form>
          }
          {
            this.data.show === 'recover' &&
            <form action="" onSubmit={(e) => { this.recover(); e.preventDefault(); }}>
              <div className="form-group has-feedback">
                <label htmlFor="">User ID</label>
                <input
                  className="form-control"
                  placeholder="User ID"
                  defaultValue={this.data.userId}
                  onChange={this.handleChange.bind(this, 'userId')}
                />
                <div className="pre-icon os-icon os-icon-user-male-circle"></div>
              </div>
              <div className="form-group has-feedback">
                <label htmlFor="">Registration Code</label>
                <input
                  className="form-control"
                  placeholder="Registration Code"
                  defaultValue={this.data.registrationCode}
                  onChange={this.handleChange.bind(this, 'registrationCode')}
                />
                <div className="pre-icon os-icon os-icon-user-male-circle"></div>
              </div>
              <div className="form-group has-feedback">
                <label htmlFor="">Registration Code Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Registration Code Password"
                  defaultValue={this.data.registrationCodePassword}
                  onChange={this.handleChange.bind(this, 'registrationCodePassword')}
                />
                <div className="pre-icon os-icon os-icon-fingerprint"></div>
              </div>
              <div className="form-group has-feedback">
                <label htmlFor="">New Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="New Password"
                  defaultValue={this.data.newPassword}
                  onChange={this.handleChange.bind(this, 'newPassword')}
                />
                <div className="pre-icon os-icon os-icon-fingerprint"></div>
              </div>
              <div className="form-group has-feedback">
                <label htmlFor="">Confirm New Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm New Password"
                  defaultValue={this.data.confirmNewPassword}
                  onChange={this.handleChange.bind(this, 'confirmNewPassword')}
                />
                <div className="pre-icon os-icon os-icon-fingerprint"></div>
              </div>
              <div className="buttons-w">
                <button
                  className="btn btn-primary"
                  disabled={User.loggingIn}
                  onClick={this.resetPassword.bind(this)}
                >
                  Reset
                </button>
                <button className="btn btn-default float-right" disabled={User.loggingIn} onClick={() => this.data.show = 'login'}>Login</button>
              </div>

            </form>
          }
          <h5 className="auth-header">
            Disclaimer
          </h5>
          <form action="" onSubmit={this.login.bind(this)}>
            <div className="form-group">
              <p style={{ fontSize: 12, textAlign: 'justify' }}>This website collects personal information from users, including but not limited to ip's and photos. By using this website, you consent to the collection, storage, and use of your information.</p>
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default Login;

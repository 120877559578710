import React from 'react';

import './style.css';

const Loader = ({ show }) => {
  return (
    <div className={`loader-container ${show ? '' : 'd-none'}`}>
      <div>
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
  );
};

export default Loader;

import { observable } from 'mobx';
import { isAfter } from 'date-fns';

import { apiRequest, postFormData } from '../services/request';
import variables from '../variables';

const User = {
  deviceId: '',
  initializing: true,
  loggedIn: false,
  loggingIn: false,
  verifying: false,
  verified: false,
  resetingPassword: false,
  signingUp: false,
  updating: false,
  uploading: false,
  updatingPassword: false,
  loadingAccount: false,
  claimingRegistrationCode: false,
  claimCodeError: false,
  data: {},
  account: {},
  tracker: {
    voterKey: '',
    codeId: '',
    electionId: '',
    keyValidTill: '',
  },
  registrationCodeData: {},

  checkAuthError: function (error) {
    if (String(error).match(/operation\s{1,}not\s{1,}allowed/i)) {
      console.log('auth error found in user');
      this.logout();
    }
  },

  initialize: async function () {
    const deviceId = localStorage.getItem('deviceId');
    const deviceId2 = localStorage.getItem('deviceId2');

    if (deviceId2) {
      this.deviceId = deviceId2;
    }
    else if (deviceId) {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        this.deviceId = deviceId + '-' + data.ip;
      } catch (error) {
        this.deviceId = deviceId;
      }
    }
    const userData = localStorage.getItem('user');
    if (userData) {
      this.data = JSON.parse(userData);
      this.loggedIn = true;
    }
    const trackerData = localStorage.getItem('tracker');
    if (trackerData) {
      const data = JSON.parse(trackerData);
      if (data.voterKey && data.keyValidTill) {
        this.tracker = data;
      } else {
        this.logout();
        this.initializing = false;
        return;
      }
    } else {
      this.logout();
      this.initializing = false;
      return;
    }
    const verified = localStorage.getItem('verified');
    if (verified) {
      this.verified = JSON.parse(verified);
    }

    this.initializing = false;
  },

  login: async function (userId, password) {
    try {
      this.loggingIn = true;
      let email = `${userId}@${variables.EMAIL_DOMAIN}`;
      email = email.replace(/\//g, '-');
      if (userId.indexOf('@') >= 0) email = userId;
      const data = await apiRequest('POST', '/accounts/login', {
        url: variables.API_URL,
        body: {
          email,
          password,
          deviceId: this.deviceId,
        }
      });
      localStorage.setItem('user', JSON.stringify(data));
      const newTracker = {
        voterKey: data._voterKey,
        codeId: data._codeId,
        electionId: data._electionId,
        keyValidTill: data._keyValidTill,
      };
      const trackerData = localStorage.getItem('tracker');
      if (trackerData) {
        const tracker = JSON.parse(trackerData);
        if (tracker.keyValidTill && tracker.voterKey) {
          if (isAfter(new Date(), new Date(tracker.keyValidTill)) || newTracker.voterKey === this.deviceId) {
            localStorage.setItem('tracker', JSON.stringify(newTracker));
            this.tracker = newTracker;
          } else {
            this.tracker = tracker;
          }
        }
        else {
          localStorage.setItem('tracker', JSON.stringify(newTracker));
          this.tracker = newTracker;
        }
      } else {
        localStorage.setItem('tracker', JSON.stringify(newTracker));
        this.tracker = newTracker;
      }
      this.loggingIn = false;
      this.data = data;
      this.loggedIn = true;
      return data;
    } catch (error) {
      console.log(error);
      this.loggingIn = false;
      throw new Error('User id or password incorrect');
    }
  },

  verify: async function (image) {
    try {
      this.verifying = true;

      const data = await apiRequest('POST', '/registrationCodes/verify', {
        url: variables.API_URL,
        body: {
          image,
          codeId: this.data._codeId,
          voterKey: this.tracker.voterKey,
        },
        headers: {
          authorization: `Bearer ${this.data._token}`
        }
      });
      this.verifying = false;
      this.verified = true;
      localStorage.setItem('verified', JSON.stringify(true));
      return data;
    } catch (error) {
      this.verifying = false;
      console.log(error);
      throw new Error('unable to pass verification stage');
    }
  },

  resetPassword: async function (request) {
    try {
      const { userId } = request;
      this.resetingPassword = true;
      let email = `${userId}@${variables.EMAIL_DOMAIN}`;
      email = email.replace(/\//g, '-');
      if (userId.indexOf('@') >= 0) email = userId;
      const data = await apiRequest('POST', '/accounts/resetPassword', {
        url: variables.API_URL,
        body: {
          ...request, email,
        }
      });
      this.resetingPassword = false;
      return data;
    } catch (error) {
      this.resetingPassword = false;
      throw new Error('Error reseting password');
    }
  },

  claimRegistrationCode: async function (identifier, password) {
    try {
      this.claimingRegistrationCode = true;
      this.claimCodeError = false;
      const data = await apiRequest('POST', '/registrationCodes/claim', {
        url: variables.API_URL,
        body: { identifier, password },
        headers: {
          authorization: `Bearer ${this.data._token}`
        }
      });
      this.claimingRegistrationCode = false;
      this.claimCodeError = false;
      return data;
    } catch (error) {
      this.claimingRegistrationCode = false;
      this.claimCodeError = true;
      throw new Error(error.message);
    }
  },

  loadAccount: async function () {
    try {
      if (!this.data._token) return;
      this.loadingAccount = true;
      const [account] = await apiRequest('GET', '/accounts', {
        url: variables.API_URL,
        query: { user: this.data._id },
        headers: {
          authorization: `Bearer ${this.data._token}`
        }
      });
      this.account = account;
      this.loadingAccount = false;
      return account;
    } catch (error) {
      this.loadingAccount = false;
      this.checkAuthError(error);
      throw new Error(error.message);
    }
  },

  /**
   * 
   * @param {{
   * }} body 
   */
  signup: async function (body) {
    try {
      this.signingUp = true;
      const data = await apiRequest('POST', '/signup', {
        url: variables.API_URL,
        body
      });
      this.signingUp = false;
      localStorage.setItem('user', JSON.stringify(data));
      this.data = data;
      this.loggedIn = true;
      return data;
    } catch (error) {
      this.signingUp = false;
      if (error.message === "email already exists") {
        throw new Error('Someone has registered with the reg number');
      }
      throw new Error(error.message);
    }
  },

  confirmEmail: async function (key) {
    try {
      this.loggingIn = true;
      const data = await apiRequest('POST', '/verify', {
        url: variables.API_URL,
        body: {
          key
        }
      });
      const [account] = await apiRequest('GET', '/accounts', {
        url: variables.API_URL,
        query: { user: data._id },
        headers: {
          authorization: `Bearer ${data._token}`
        }
      });
      localStorage.setItem('user', JSON.stringify({ ...data, account }));
      this.loggingIn = false;
      this.data = data;
      this.loggedIn = true;
      return data;
    } catch (error) {
      this.loggingIn = false;
      throw new Error(error.message);
    }
  },

  update: async function (update) {
    try {
      this.updating = true;
      const id = this.account._id;
      const result = await apiRequest('PUT', `/accounts/${id}`, {
        url: variables.API_URL,
        headers: {
          authorization: `Bearer ${this.data._token}`
        },
        body: update
      });
      this.account = result;
      this.updating = false;
      return result;
    } catch (error) {
      this.updating = false;
      throw new Error(error.message);
    }
  },

  updatePassword: async function (update) {
    try {
      this.updatingPassword = true;
      const id = this.data._id;
      const result = await apiRequest('PUT', `/users/${id}`, {
        url: variables.API_URL,
        headers: {
          authorization: `Bearer ${this.data._token}`
        },
        body: update
      });
      this.updatingPassword = false;
      return result;
    } catch (error) {
      this.updatingPassword = false;
      throw new Error(error.message);
    }
  },

  uploadImage: async function (file) {
    try {
      this.uploading = true;
      const result = await postFormData(`${variables.API_URL}/uploads`, { file }, {
        authorization: `Bearer ${this.data._token}`
      });
      this.uploading = false;
      return result;
    } catch (error) {
      this.uploading = false;
      throw new Error(error.message);
    }
  },

  logout: async function () {
    this.loggedIn = false;
    this.verified = false;
    this.verifying = false;
    localStorage.removeItem('user');
    localStorage.removeItem('verified');
    this.data = {};
    this.account = {};
  },

  getRequestConfig: function ({ query }) {
    return {
      url: variables.API_URL,
      query,
      headers: {
        authorization: `Bearer ${this.data._token}`
      }
    };
  }
};

export default observable(User);
